import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TestimonialImage = () => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "testimonials/julia-g.jpg" }) {
        childImageSharp {
          fixed(width: 100, quality: 90) {
            ...GatsbyImageSharpFixed  
          }
        }
      }
    }
  `)

    return <Img fluid={data.placeholderImage.childImageSharp.fixed}/>
}

export default TestimonialImage
