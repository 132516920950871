import React, { useState } from 'react'
import styled from 'styled-components'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import Icon from '@mdi/react'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import Amanda from './Images/Testimonials/Amanda';
import Anna from './Images/Testimonials/Anna';
import Julia from './Images/Testimonials/Julia';
import Kenna from './Images/Testimonials/Kenna';
import Roe from './Images/Testimonials/Roe';
import Valerie from './Images/Testimonials/Valerie';

const Section = styled.div`
	padding: 8rem 0;
	margin: 0 auto;
	
	button {
	    background: none;
	}
	
	.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
	    background: orange;
	}
`

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 3rem;
`;

const Title = styled.h2`
    position: relative;
	text-align: center;
	font-size: 28px;
	color: #000;
	
	@media (min-width: 768px) {
	    font-size: 32px;
	}
`

const TestimonialCard = styled.div`
    position: relative;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 30px 0px;
    padding: 3rem 2rem 2rem;
    width: 300px;

	@media (min-width: 768px) {
		width: 600px;
		padding: 5rem 4rem 4rem;
	}
`

const Photo = styled.div`
    position: absolute;
    top: -45px;
    left: calc(50% - 45px);
    
    .gatsby-image-wrapper {
        height: 90px;
        width: 90px;
        border-radius: 90px;
    }
`

const Body = styled.div`
	margin-bottom: 2rem;    
	
	ul {
	    margin-bottom: 1rem;
	    list-style-type: circle;
	    padding-left: 2rem;
	}
	
	p {
	    margin-bottom: 1rem;
	};
	
	@media (min-width: 768px) {
        font-size: 18px;
	}
`

const Name = styled.div`
    color: #000;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1.4px;
	font-weight: bold;
`

const PersonTitle = styled.div`
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
	letter-spacing: 1.4px;
`

const CardWrapper = styled.div`
	padding: 3rem 0;
`;

const Button = styled.button`
	border: none;
	outline: none;
	width: 45px;
	height: 45px;
	cursor: pointer;
	background: transparent;
	margin: 1rem;
	
	svg {
	    color: orange;
	}
`;

const JessicaTestimonials = () => {
    const [slide, setSlide] = useState(0);

    return (
        <Section>
            <TitleWrapper>
                <Button onClick={() => setSlide(slide - 1)}>
                    <Icon path={mdiChevronLeft} />
                </Button>

                <Title>
                    What People<br />Are Saying
                </Title>

                <Button onClick={() => setSlide(slide + 1)}>
                    <Icon path={mdiChevronRight} />
                </Button>
            </TitleWrapper>

            <Carousel
                keepDirectionWhenDragging
                value={slide}
                onChange={setSlide}
                // autoPlay={5000}
                infinite
                centered
                dots
                slidesPerPage={1}
                breakpoints={{
                    1425: {
                        slidesPerPage: 1,
                    },
                    1920: {
                        slidesPerPage: 2,
                        clickToChange: true
                    }
                }}>
                <CardWrapper>
                    <TestimonialCard>
                        <Photo><Julia /></Photo>
                        <Body>My sessions with Jess turned life around for me, without a doubt. Jess is so caring and understanding, and explains everything clearly. She's also very quick and can help release a lot in a short space of time! For me, I've had a massive increase in my patience and being more in tune with my body. I struggle with various health issues that affect me mentally, but I'm more able to stay calm and recognise them as a symptom, therefore negating their effect on my life. My marriage has improved and I feel lighter and less depressed. She makes you feel like a life long friend who you can be relaxed around. Thank you Jessica and I'll be in touch again soon!</Body>
                        <Name>Julia G.</Name>
                        <PersonTitle>Satisfied Client</PersonTitle>
                    </TestimonialCard>
                </CardWrapper>

                <CardWrapper>
                    <TestimonialCard>
                        <Photo><Valerie /></Photo>
                        <Body>Jess is a sensitive, intuitive healer who has a gigantic heart! My three sessions with her were immediately helpful for some pain in my body as well as for some emotions I was experiencing. Jess works well with the Emotion Code and Body Code process - I am fully confident you will find relief and clarity as a result of working with her! Yes, Emanuel Zevallos, she is an angel! I will definitely schedule more work with her in the future. Thanks, Jess! <span role="img" aria-label="heart emoji">❤️</span></Body>
                        <Name>Valerie B.</Name>
                        <PersonTitle>Satisfied Client</PersonTitle>
                    </TestimonialCard>
                </CardWrapper>

                <CardWrapper>
                    <TestimonialCard>
                        <Photo><Kenna /></Photo>
                        <Body>Jessica is such a kind and caring person. She cares about others and truly wants to help them heal from whatever it is they are dealing with. I’d definitely recommend her to anyone!</Body>
                        <Name>Kenna K.</Name>
                        <PersonTitle>Satisfied Client</PersonTitle>
                    </TestimonialCard>
                </CardWrapper>

                <CardWrapper>
                    <TestimonialCard>
                        <Photo><Anna /></Photo>
                        <Body>Jess is amazing!! My two daughters and I have had Jess do several sessions on us and we feel so incredibly light and filled with such peace! Jess found an unhealthy gut chord between myself and my youngest daughter and since she’s released that and replaced it with a healthy chord, my daughter gives me a hug daily which is HUGE for her! My relationship with my girls has always been good but the deep connection we feel since the healing sessions is just incredible! So excited to have found the Emotion Code as a source of healing!</Body>
                        <Name>Anna D.</Name>
                        <PersonTitle>Satisfied Client</PersonTitle>
                    </TestimonialCard>
                </CardWrapper>

                <CardWrapper>
                    <TestimonialCard>
                        <Photo><Amanda /></Photo>
                        <Body>Since Jessica released my heart wall, I can definitely notice a difference and my mom does too in me and her as well from having sessions with Jessica!  Mom tells me I am way more confident and calm - a whole new person!  Especially since I was struggling with ptsd triggers from that past relationship. I don’t hide anymore in public and don’t try to be a wallflower. I find myself approaching and chatting with strangers and just feel so much better. Which Inspired me to enrol in the Emotion Code program and become a practioner!!</Body>
                        <Name>Amanda M.</Name>
                        <PersonTitle>Satisfied Client</PersonTitle>
                    </TestimonialCard>
                </CardWrapper>

                <CardWrapper>
                    <TestimonialCard>
                        <Photo><Roe /></Photo>
                        <Body>I can’t thank Jessica Zevallos enough for changing my world around!!  Jessica is a talented, sweet, caring & empathic practitioner who feels and understands people’s hearts.  As a certified Emotion & Body Code practitioner, she truly knows how to use her talents!!  She touched upon relationships I wanted to improve upon and amazingly they transformed in a wonderfully huge way!!!  I was blown away with her intuition and healing skills!!  I would recommend Jessica Zevallos to anyone searching for a better life!! She’s the real deal!! <span role="img" aria-label="heart emoji">❤️</span></Body>
                        <Name>Roe S.</Name>
                        <PersonTitle>Satisfied Client</PersonTitle>
                    </TestimonialCard>
                </CardWrapper>
            </Carousel>
        </Section>
    )
}

export default JessicaTestimonials
