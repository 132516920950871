import styled from 'styled-components';

const LOGO_SIZE_LG = '320px';

export const Page = styled.div`
    color: #000;
	
	@media (min-width: 1064px) {
	    
    }
`;

export const Section = styled.div`
    display: flex;
    max-width: 1200px;
	margin: 0 auto;
	padding-bottom: 2rem;
`;

export const ImageWrapper = styled.div`
	width: 400px;
`;

export const Title = styled.div`
    position: relative;
    margin-bottom: 3rem;
    padding: 1rem 0;
    
    @media (min-width: 1064px) {
	    padding: 6rem 0;
    }
    
    svg {
        display: none;
        
        @media (min-width: 1064px) {
            display: initial;
            position: absolute;
            width: 200px;
            top: 50px;
            left: -75px;
            opacity: 0.1;
        }
    }
`;

export const Name = styled.h1`
    font-family: Lato, sans-serif;
	font-size: 54px;
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: 8px;
	line-height: 1;
	margin-bottom: 1rem;
	text-align: center;
	
	@media (min-width: 1064px) {
	    text-align: left;
	}
`;

export const Column = styled.div`
    flex: 1 1 66%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
	margin: 0 auto;
	
	@media (min-width: 1064px) {
        padding: 4rem;
        align-items: flex-start;
	}
	    
	p {
	    font-family: Merriweather, serif;
	    margin-bottom: 2.5rem;
	    font-size: 18px;
        line-height: 2;
        max-width: 550px;
	}
`;

export const Status = styled.h2`
	font-family: Lato, sans-serif;
	font-size: 18px;
	font-style: italic;
	text-align: center;
	
	@media (min-width: 1064px) {
	    text-align: left;
	}
`;

export const ImageColumn = styled(Column)`
    display: none;
    
    @media (min-width: 1064px) {
        display: initial;
        flex: 0 1 33%;
    }
`;

export const MobileImage = styled.div`
	width: 100%;
	max-width: 550px;
	margin-bottom: 2.5rem;
	
	@media (min-width: 1064px) {
        display: none;
    }
`;

export const MediaSection = styled(Section)`
    color: #fff;
	background-color: #3F3F55;
	max-width: 100%;
`;

export const Container = styled.div`
    width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 4rem 2rem;
	
	@media (min-width: 1064px) {
        padding: 6rem;
	}
`;

export const MediaLink = styled.a`
	width: 100%;
	display: flex;
	background-color: #fff;
	border-radius: 10px;
	flex-direction: column;
	max-width: 550px;
	margin: 0 auto 4rem;
	
	&:last-child {
	    margin-bottom: 0;
	}
	
	@media (min-width: 1064px) {
		flex-direction: row;	
		max-width: 1200px;
		box-shadow: 20px 20px 20px 0 rgba(0,0,0,0.5);
	}
`;

export const MediaLogo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3rem;
	height: 200px;
	border-bottom: 1px solid #e5e5e5;
	
	img {
	    width: 252px;
	}
	
	@media (min-width: 1064px) {
		border-bottom: none;
		border-right: 1px solid #e5e5e5;
		flex: 0 0 ${LOGO_SIZE_LG};
	}
`;

export const MediaDescription = styled.div`
	color: #000;
	font-family: "Lato", sans-serif;
	font-weight: 400;
	display: flex;
	flex-direction: column;
	padding: 2rem;
	
	@media (min-width: 1064px) {
		width: calc(100% - ${LOGO_SIZE_LG});
	}
`;

export const MediaTitle = styled.div`
	font-size: 22px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 1rem;
`;

export const MediaBlurb = styled.div`
	color: #637282;
	margin-bottom: 1rem;
	height: 44px;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 2;
`;

export const MediaName = styled.div`
	color: #637282;
`;
