import React from 'react';
import Logo from './Logo';
import Background from './Background';
import styled from 'styled-components';

const Container = styled.div`
	position: relative;
    background-color: #e1e1e1;
	padding: 6rem 2rem;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;

const BackgroundWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	pointer-events: none;
	
	& > svg {
		z-index: 1;
        position: absolute;
	    color: #404056;
	    opacity: 0.2;
	    top: -50%;
	    left: -50%;
	    width: 200%;
	    height: 200%;
	}
`;

const LogoWrapper = styled.div`
	width: 200px;
	
	svg {
		width: 200px;
	}
`;

const Button = styled.button`
    cursor: pointer;
    border: none;
    outline: none;
	background: #3f3f55;
	font-size: 22px;
	border-radius: 100px;
	padding: 20px 60px;
	color: #fff;
	margin-bottom: 6rem;
    box-shadow: 8px 9px 20px 0 rgba(0, 0, 0, 0.28);
    -webkit-tap-highlight-color: transparent;
    z-index: 2;
`;

const QuestionsWrapper = styled.div`
	margin-bottom: 5rem;
	
	p {
		text-align: center;
		font-size: 22px;
		
		@media (min-width: 1125px) {
			font-size: 28px;
		}
		
		a {
			border-bottom: 1px solid black;
		}
	}
`;

const BookNow = props => {
	return (
		<Container>
			<QuestionsWrapper>
				<p>Questions? Comments? Concerns?</p>
				<p> Feel free to reach out to me at <a href="mailto:schedulemyhealing@gmail.com">schedulemyhealing@gmail.com</a></p>
			</QuestionsWrapper>
			<BackgroundWrapper><Background/></BackgroundWrapper>
            <a href="https://compasshealing.as.me/"><Button>Book A Session With {props.person}!</Button></a>
            <LogoWrapper><Logo/></LogoWrapper>
		</Container>
	)
}

export default BookNow;
