import React from 'react';
import Image from '../components/Images/Jessica';
import Jessica1 from '../components/Images/Jessica1';
import Jessica2 from '../components/Images/Jessica2';
import Jessica5 from '../components/Images/Jessica5';
import { Page, ImageWrapper, Section, Column, ImageColumn, MobileImage, Name, Status, Title } from '../components/page.styles';
import JessicaTestimonials from '../components/JessicaTestimonials';
import BookNow from '../components/BookNow';
import Brandmark from '../components/Brandmark';
import Microdata from '../components/Microdata';
import SEO from '../components/seo';

const Jessica = () => {
    return (
        <>
            <SEO title="Compass Healing Practitioners | Leading Emotion Code, Body Code Practitioners"/>
            <Microdata/>
            <Page>
                <Section>
                    <ImageColumn>
                        <ImageWrapper><Image/></ImageWrapper>
                        <ImageWrapper><Jessica1/></ImageWrapper>
                        <ImageWrapper><Jessica2/></ImageWrapper>
                        <ImageWrapper><Jessica5/></ImageWrapper>
                    </ImageColumn>
                    <Column>

                        <Title>
                            <Brandmark/>
                            <Name>Jessica<br />Zevallos</Name>
                            <Status>CECP, CBCP</Status>
                            <Status>Compass Healing Practitioner</Status>
                        </Title>

                        <MobileImage><Image/></MobileImage>

                        <p>Hi! I am Jessica. I have lived in Utah in my entire life. I have two sweet boys and am married to my best friend, Emanuel. We have built such a beautiful life together. Emanuel introduced me to the Emotion Code in 2019 and my life has never been the same since.</p>
                        <p>I am a survivor of narcissistic abuse and want to help empower others! You, yes YOU, have such potential and are capable of so much more than you even know!</p>
                        <MobileImage><Jessica1/></MobileImage>
                        <p>I've been divorced for five years, and I’ve tried several different types of healing and therapy and nothing really seemed to help me until I tried using the Emotion Code. I was struggling with massive anxiety and post-traumatic stress disorder from my previous marriage. It affected every aspect of my life and every new relationship I tried to build. I felt stuck and also had chronic pain that no doctor could properly diagnose. That was all before my life-changing Emotion Code session.</p>
                        <MobileImage><Jessica2/></MobileImage>
                        <p>During my first session we addressed the chronic pain which I had been suffering from for over five years. My little babies could not even cuddle on my chest it hurt so badly. Driving was a nightmare—I couldn't even check my blind spots! My emotion code practitioner and I were able to diagnose the cause of this tremendous pain, and it was removed on the spot, instantly! Within five minutes, too! I couldn’t believe it.</p>
                        <MobileImage><Jessica5/></MobileImage>
                        <p>I had several other sessions to lower my anxiety and my PTSD. I was able to stop all medication I was on within two weeks, which was a miracle. Previously, it took me a year to get off of them. I couldn’t believe I could quit cold turkey. I was so impacted by my sessions that I wanted to learn everything about it and get certified in order to help others who struggle.</p>
                        <p>I am also an empath and have learned how to empower myself, and I love helping others empower their gift as well. I love using crystals and helping others to understand how energy healing can empower people and change lives.</p>
                        <p>We have all suffered long enough, we don't need to spend one more day struggling! Let’s start your healing journey together and get you back to who you really are who you were meant to be!</p>
                    </Column>
                </Section>
                <JessicaTestimonials/>
                <BookNow person="Jessica"/>
            </Page>
        </>

    );
};

export default Jessica;
